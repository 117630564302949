import { CardBody, CardFooter, CardHeader } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { SkeletonTileViewProgramExperience } from '../../../Native/AEP/Common/ProgramExperience';
import { lookupAndFilter } from '../../ExperiencesDictionary';
import { ExperienceDictionaryEntryResponse } from '../../ExperiencesDictionary/Types';
import experiencesStore, { isLoaded } from '../ExperiencesStore';
import useExperiencesStore from '../ExperiencesStore/UseExperiencesStore';
import { PortalExperienceProvider } from '../PortalExperience/PortalExperienceContextProvider';
import ExperienceBody from './ExperienceBody';
import ExperienceFooter from './ExperienceFooter';
import ExperienceHeaderEndIcon from './ExperienceHeaderEndIcon';
import ExperienceHeaderLogo from './ExperienceHeaderLogo';
import ProgramExperienceCard from './ProgramExperienceCard';

function ExperienceTiles() {
  useExperiencesStore();

  if (!isLoaded(experiencesStore)) {
    return (
      <div className="mt-14 grid gap-4 px-6 sm:grid-cols-2 md:px-16 lg:grid-cols-3 xl:grid-cols-4">
        {Array.from({ length: 12 }, (_, index) => (
          <SkeletonTileViewProgramExperience key={index} />
        ))}
      </div>
    );
  }
  return (
    <div className="mt-14 grid gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {experiencesStore.state.experiences.payload.experiences
        .reduce(lookupAndFilter, [] as ExperienceDictionaryEntryResponse[])
        .map((experience) => {
          return (
            <PortalExperienceProvider experience={experience} key={experience.payload.id}>
              <ProgramExperienceCard
                className={
                  'pointer-events-none h-full min-h-[14rem] w-[20rem] bg-white text-gray-800 shadow-tile-preview'
                }
              >
                <CardHeader
                  className={'h-[2.375rem] rounded-t py-1.5'}
                  endContent={<ExperienceHeaderEndIcon />}
                >
                  <ExperienceHeaderLogo />
                </CardHeader>
                <CardBody>
                  <ExperienceBody />
                </CardBody>
                <CardFooter>
                  <ExperienceFooter />
                </CardFooter>
              </ProgramExperienceCard>
            </PortalExperienceProvider>
          );
        })}
    </div>
  );
}

export default observer(ExperienceTiles);
