import { Decoder, number } from 'jsonous';
import { just, Maybe, nothing } from 'maybeasy';
import { action, makeObservable, observable } from 'mobx';
import { useEffect, useState } from 'react';
import { Task } from 'taskarian';
import { callApi } from '../../../Appy';
import useCaseStore from '../../../RegistrationInvitation/UseCaseStore';
import { resourceDecoder } from '../../../Resource/Decoders';
import { Link, Resource } from '../../../Resource/Types';
import { usePortalExperienceContext } from './UsePortalExperienceContext';

type PortalExperiencePrices = Record<string, Maybe<Resource<number>>>;

const pricesResoureDecoder: Decoder<Resource<number>> = resourceDecoder(number);

class PortalExperiencePricesStore {
  @observable prices: PortalExperiencePrices = {};

  constructor() {
    makeObservable(this);
  }

  @action
  getPrice = async (link: Link): Promise<Maybe<Resource<number>>> => {
    const price = this.prices[link.href];
    if (price) {
      return price;
    }

    return callApi(pricesResoureDecoder, {}, link)
      .map((resource) => {
        this.prices[link.href] = just(resource);
        return this.prices[link.href];
      })
      .orElse((err) => {
        console.warn('Failed to fetch price', err);
        this.prices[link.href] = nothing();
        return Task.succeed(this.prices[link.href]);
      })
      .resolve();
  };
}

const portalExperiencePricesStore = new PortalExperiencePricesStore();
export default portalExperiencePricesStore;

export function usePortalExperiencePrice() {
  const [experiencePrice, setExperiencePrice] = useState<Maybe<Resource<number>>>(nothing());
  const experience = usePortalExperienceContext();

  useEffect(() => {
    const useCaseState = useCaseStore.state;
    if (useCaseState.kind !== 'use-case-store-state-loaded') {
      return;
    }

    if (useCaseState.useCase.payload.commerceType === 'not-available') {
      return;
    }

    const useCaseId = useCaseState.useCase.payload.id;

    experience
      .map((lookupResult) => lookupResult.links.experiencePrices)
      .map((link) => {
        return {
          ...link,
          href: link.href.replace('{use_case_id}', useCaseId.toString()),
        };
      })
      .do((link) => {
        portalExperiencePricesStore.getPrice(link).then(setExperiencePrice);
      });
  }, [experience, useCaseStore.state]);

  return experiencePrice;
}
