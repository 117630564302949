import { computed, observable, runInAction } from 'mobx';
import { useEffect } from 'react';
import { includes } from '../../Collections';
import { Link } from '../../Resource/Types';
import { useQueryWithDecoder } from '../../UseQueryWithDecoder';
import experienceFiltersStore from '../Registration/ExperienceSelectionView/FiltersStore';
import { matchesSearchResults } from '../Registration/ExperienceSelectionView/SearchStore';
import { ExperienceIDResponse } from '../Registration/ExperiencesStore';
import { ExperienceDictionaryEntryResponse, experiencesDictionaryDecoder } from './Types';

export const experiencesDictionary = observable<
  Record<number, ExperienceDictionaryEntryResponse | undefined>
>({});

export const experiencesDictionaryValues = computed(
  (): ReadonlyArray<ExperienceDictionaryEntryResponse> => {
    return Object.values(experiencesDictionary).filter(
      (entry): entry is ExperienceDictionaryEntryResponse => entry !== undefined,
    );
  },
);

export function useExperiencesDictionary(links: ReadonlyArray<Link>) {
  const { data, error, isLoading } = useQueryWithDecoder(
    'experiences-dictionary',
    links,
    experiencesDictionaryDecoder,
  );

  useEffect(() => {
    if (data) {
      console.debug(
        `Adding ${data.payload.length} experiences to the dictionary store.`,
        data.payload.map((experience) => experience.payload.id).sort((a, b) => a - b),
      );
      // Add the experiences to the store
      runInAction(() => {
        data.payload.forEach((experience) => {
          experiencesDictionary[experience.payload.id] = experience;
        });
      });
      // Log the current state of the store for debugging
      console.debug('Current experiences in the dictionary store:', experiencesDictionary);
    }
  }, [data]);

  return {
    experiencesDictionary: data,
    error,
    isLoading,
  };
}

/**
 * Determines if a given experience matches all the applied search and filter criteria.
 *
 * @param experience - The experience entry to evaluate against the search and filter criteria.
 * @returns A boolean indicating whether the experience satisfies all the filters and search conditions.
 */
export function matchesSearchAndFilters(
  experience: ExperienceDictionaryEntryResponse,
): experience is ExperienceDictionaryEntryResponse {
  return (
    matchesSchoolPartnerFilter(experience) &&
    matchesLanguageFilter(experience) &&
    matchesTimeCommitmentFilter(experience) &&
    matchesCompetencyFilter(experience) &&
    matchesSearchResults(experience)
  );
}

/**
 * Determines whether an experience matches the school partner filter.
 *
 * This function checks if the provided experience satisfies the currently applied
 * school partner filter. If no filter is applied or the filter store is not loaded,
 * the function defaults to returning `true`.
 *
 * @param experience - The experience entry to evaluate against the school partner filter.
 * @returns `true` if the experience matches the filter or if no filter is applied; otherwise, `false`.
 */
function matchesSchoolPartnerFilter(experience: ExperienceDictionaryEntryResponse): boolean {
  if (experienceFiltersStore.state.kind === 'filter-store-loaded') {
    const schoolPartnerFilter = experienceFiltersStore.state.schoolPartnerFilterStore;
    const partners = schoolPartnerFilter.appliedValues;
    if (partners.length === 0) {
      return true; // No filter applied means all experiences match
    }
    return partners.some((partner) => experience.payload.schoolPartnerName.text === partner);
  }
  return true; // Default to true if filters are not loaded
}

/**
 * Determines whether a given experience matches the currently applied language filter.
 *
 * @param experience - The experience entry to evaluate against the language filter.
 * @returns `true` if the experience matches the language filter or if no filter is applied;
 *          otherwise, `false`.
 *
 * The function checks if the `experienceFiltersStore` is in a loaded state and retrieves
 * the applied language filter values. If no languages are selected in the filter, all
 * experiences are considered a match. Otherwise, it verifies if the experience supports
 * any of the selected languages.
 */
function matchesLanguageFilter(experience: ExperienceDictionaryEntryResponse): boolean {
  if (experienceFiltersStore.state.kind === 'filter-store-loaded') {
    const languageFilter = experienceFiltersStore.state.languageFilterStore;
    const languages = languageFilter.appliedValues;
    if (languages.length === 0) {
      return true; // No filter applied means all experiences match
    }
    const selectedLangauges = new Set(languages);
    return experience.payload.supportedLanguageCodes.some((code) => selectedLangauges.has(code));
  }
  return true; // Default to true if filters are not loaded
}

/**
 * Determines whether an experience matches the competency filter criteria.
 *
 * This function checks if the provided experience satisfies the competency
 * filter applied in the `experienceFiltersStore`. If no filters are applied
 * or the filter store is not loaded, the function defaults to returning `true`.
 *
 * @param experience - The experience entry to evaluate against the competency filter.
 * @returns `true` if the experience matches the competency filter or if no filter is applied;
 *          otherwise, `false`.
 */
function matchesCompetencyFilter(experience: ExperienceDictionaryEntryResponse): boolean {
  if (experienceFiltersStore.state.kind === 'filter-store-loaded') {
    const competencyFilter = experienceFiltersStore.state.competencyFilterStore;
    const selectedCompetencies = competencyFilter.appliedValues;
    if (selectedCompetencies.length === 0) {
      return true; // No filter applied means all experiences match
    }
    return experience.payload.primaryCompetencies.some((competency) =>
      includes(competency.text, selectedCompetencies),
    );
  }
  return true; // Default to true if filters are not loaded
}

/**
 * Determines whether an experience matches the time commitment filter.
 *
 * This function checks if the given experience satisfies the currently applied
 * time commitment filter. If no filter is applied or the filter store is not loaded,
 * it defaults to returning `true`, meaning all experiences match.
 *
 * @param experience - The experience entry to evaluate against the time commitment filter.
 * @returns `true` if the experience matches the filter or no filter is applied; otherwise, `false`.
 */
function matchesTimeCommitmentFilter(experience: ExperienceDictionaryEntryResponse): boolean {
  if (experienceFiltersStore.state.kind === 'filter-store-loaded') {
    const timeCommitmentFilter = experienceFiltersStore.state.durationFilterStore;
    const timeCommitments = timeCommitmentFilter.appliedValues;
    if (timeCommitments.length === 0) {
      return true; // No filter applied means all experiences match
    }
    return experience.payload.totalHours
      .map((d) => includes(String(d), timeCommitments))
      .getOrElse(() => includes('0', timeCommitments));
  }
  return true; // Default to true if filters are not loaded
}

/**
 * Filters and accumulates experiences from the experiences dictionary based on a given experience ID
 * and whether they match specific search and filter criteria.
 *
 * @param acc - The accumulator array of `ExperienceDictionaryEntryResponse` objects.
 * @param experienceID - The ID of the experience to look up in the dictionary.
 * @returns The updated accumulator array containing the filtered experiences.
 */
export function lookupAndFilter(
  acc: ExperienceDictionaryEntryResponse[],
  experienceID: ExperienceIDResponse,
): ExperienceDictionaryEntryResponse[] {
  const experience = experiencesDictionary[experienceID.payload.id];
  if (typeof experience !== 'undefined' && matchesSearchAndFilters(experience)) {
    acc.push(experience);
  }
  return acc;
}
