import { array, InferType } from 'jsonous';
import { action, observable, runInAction } from 'mobx';
import { callApi } from '../../../Appy';
import { createDecoderFromStructure } from '../../../Decoders/Structured';
import { linkDecoder } from '../../../Resource/Decoders';
import { Link } from '../../../Resource/Types';
import { ExperienceDictionaryEntryResponse } from '../../ExperiencesDictionary/Types';
import { ExperienceID, experienceIDDecoder, ExperienceIDResponse } from '../ExperiencesStore';

const searchResultResourceDecoder = createDecoderFromStructure({
  payload: experienceIDDecoder,
  links: {
    self: linkDecoder,
  },
});
export type SearchResultResource = InferType<typeof searchResultResourceDecoder>;

const searchResultsDecoder = createDecoderFromStructure({
  payload: array(experienceIDDecoder),
  links: {
    self: linkDecoder,
  },
});
export type SearchResults = InferType<typeof searchResultsDecoder>;

const searchResults = observable<ExperienceID>([]);
const isLoading = observable.box(false);

export function useDiscoverySearchResults() {
  const loadResults = action((link: Link) => {
    if (isLoading.get()) {
      return;
    }

    isLoading.set(true);
    callApi(searchResultsDecoder, {}, link).fork(
      action((error) => {
        console.error('Error loading search results:', error);
        isLoading.set(false);
      }),
      action((results) => {
        console.debug('Search results loaded:', results.payload);
        isLoading.set(false);
        searchResults.replace(results.payload);
      }),
    );
  });

  return {
    isLoading,
    searchResults,
    loadResults,
  };
}

export function matchesSearchResults(
  experience: ExperienceIDResponse | ExperienceDictionaryEntryResponse,
): boolean {
  if (searchResults.length === 0) {
    return true; // If no search results, show all experiences
  }
  return searchResults.map((result) => result.id).includes(experience.payload.id);
}

export function clearSearchResults() {
  runInAction(() => {
    searchResults.replace([]);
  });
}
