import { Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import {
  ExperienceWhatYouWillLearn,
  ExperienceWhoShouldAttend,
} from '../../../Native/AEP/ExperienceDetailView/Common';
import JourneyCard from '../../../Native/AEP/ExperienceDetailView/LearnMoreLearningJourney/LearningJourneyDetails/JourneyCard';
import SchoolPartners from '../../../Native/AEP/ExperienceDetailView/LearnMoreLearningJourney/LearningJourneyDetails/SchoolPartners';
import Steps from '../../../Native/AEP/ExperienceDetailView/LearnMoreLearningJourney/LearningJourneyDetails/Steps';
import { T } from '../../../Translations';
import { ExperienceDictionaryEntryResponse } from '../../ExperiencesDictionary/Types';
import { ProgramSequenceProductDetails } from '../ProductDetails/ProductDetailsStore';
import ExperienceOverview from './ExperienceOverview';
import ExperienceYouWillGain from './ExperienceYouWillGain';

interface Props {
  experience: ExperienceDictionaryEntryResponse;
  productDetails: ProgramSequenceProductDetails;
}

function LearningJourneyDetails({ experience, productDetails }: Props) {
  return (
    <div className="box-border flex flex-col gap-y-12 bg-white px-4 py-12 sm:p-12 md:gap-y-14">
      <SchoolPartners productDetails={productDetails} />
      <div className="relative h-full w-full">
        <Typography variant="h5" fontWeight="bold" className="mb-11">
          <T kind="Your Learning Journey" />
        </Typography>
        <div className="flex flex-col gap-y-3">
          {productDetails.sections.map((section, index) => (
            <section key={index} className="flex flex-col gap-x-7 sm:flex-row">
              <Steps index={index} length={productDetails.sections.length} />
              <JourneyCard section={section} />
            </section>
          ))}
        </div>
      </div>
      <ExperienceOverview experience={experience} />
      <ExperienceOverviewDetails experienceEntry={experience} />
    </div>
  );
}

const ExperienceOverviewDetails = observer(
  ({ experienceEntry }: { experienceEntry: Props['experience'] }) => {
    const entry = experienceEntry.payload;
    return (
      <div className="flex flex-col gap-6 xl:flex-row">
        <ExperienceWhatYouWillLearn experience={entry} />
        <div className="flex basis-4/12 flex-col gap-6 lg:flex-row xl:flex-col">
          <ExperienceWhoShouldAttend experience={entry} />
          <ExperienceYouWillGain experience={entry} />
        </div>
      </div>
    );
  },
);

export default observer(LearningJourneyDetails);
