import { Maybe, nothing } from 'maybeasy';
import { useEffect, useState } from 'react';
import availableLanguagesStore from '.';
import { LanguagesResource } from '../../../../ProfileFormStore/Types';
import { usePortalExperienceContext } from '../../PortalExperience/UsePortalExperienceContext';

function useAvailableLanguages() {
  const experience = usePortalExperienceContext();
  const [languages, setLanguages] = useState<Maybe<LanguagesResource>>(nothing());
  useEffect(() => {
    experience
      .map((e) => e.links.supportedLanguages)
      .do((link) => {
        availableLanguagesStore.getLanguages(link).then(setLanguages);
      });
  }, [experience]);

  return {
    languages,
  };
}

export default useAvailableLanguages;
