import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { findLink } from '../../../LinkyLinky';
import useCaseStore from '../../../RegistrationInvitation/UseCaseStore';
import { Link } from '../../../Resource/Types';
import Filters from './Filters';
import SearchBox from './SeachBox';
import { useDiscoverySearchResults } from './SearchStore';

const useCaseLinks = (): ReadonlyArray<Link> => {
  if (useCaseStore.state.kind === 'use-case-store-state-loaded') {
    return useCaseStore.state.useCase.links;
  }
  return [];
};

function fromTemplateLink(link: Link): (replaceWith: string) => Link;
function fromTemplateLink(link: Link, replaceWith: string): Link;
function fromTemplateLink(link: Link, replaceWith?: string) {
  const doit = (replaceWith: string): Link => {
    const param = new URLSearchParams();
    param.set('search', replaceWith);
    const searchHref = link.href.replace('{search_term}', param.get('search') ?? '');
    console.debug(searchHref);
    return {
      ...link,
      href: searchHref,
    };
  };

  return typeof replaceWith === 'undefined' ? doit : doit(replaceWith);
}

const _Search = observer(() => {
  const { loadResults } = useDiscoverySearchResults();

  return findLink('search-experiences', useCaseLinks())
    .map((link) => (
      <SearchBox
        onSubmit={(value) => {
          console.debug('SearchBox onSubmit', value);
          const searchLink = fromTemplateLink(link, value);
          loadResults(searchLink);
        }}
      />
    ))
    .getOrElse(emptyFragment);
});

function FilterSection() {
  return (
    <div className="sm:px-0">
      <div className="mx-auto box-border flex gap-x-2 px-6 pb-8 pt-0 xl:container md:flex-wrap md:gap-x-0 md:px-16">
        <div className="flex w-full flex-col gap-2 lg:flex-row">
          <_Search />
          <Filters />
        </div>
      </div>
    </div>
  );
}

export default observer(FilterSection);
