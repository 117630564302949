import { stringLiteral } from '@execonline-inc/decoders';
import { array, Decoder, field, InferType, number, oneOf, string } from 'jsonous';
import { just, Maybe, nothing } from 'maybeasy';
import { alreadyTranslatedTextDecoder } from '../../Decoders';
import { createDecoderFromStructure, snakeCase } from '../../Decoders/Structured';
import { offeringTypeDecoder } from '../../Native/AEP/DiscoveryPortal/ExperienceSelection/Experiences/Decoders';
import { linkDecoder, resourceDecoder } from '../../Resource/Decoders';
import { Resource } from '../../Resource/Types';
import { supportedLanguageCodeDecoder } from '../../SupportedLanguages/Decoders';

function explicitMaybeDecoder<T>(decoder: Decoder<T>): Decoder<Maybe<T>> {
  const justDecoder: Decoder<Maybe<T>> = field('kind', stringLiteral('just'))
    .andThen(() => field('value', decoder))
    .map(just);
  const nothingDecoder: Decoder<Maybe<T>> = field('kind', stringLiteral('nothing')).map(() =>
    nothing(),
  );
  return oneOf([justDecoder, nothingDecoder]);
}

export type ExperienceDictionaryEntryResponse = InferType<typeof experienceDictionaryEntryDecoder>;

const experienceDictionaryEntryPayloadDecoder = createDecoderFromStructure(
  {
    id: number,
    experienceId: string,
    offeringType: offeringTypeDecoder,
    primaryColor: string,
    secondaryColor: string,
    shortName: explicitMaybeDecoder(alreadyTranslatedTextDecoder),
    descriptor: explicitMaybeDecoder(alreadyTranslatedTextDecoder),
    title: alreadyTranslatedTextDecoder,
    description: alreadyTranslatedTextDecoder,
    facultyCount: explicitMaybeDecoder(number),
    duration: explicitMaybeDecoder(number),
    totalHours: explicitMaybeDecoder(number),
    hoursPerWeek: explicitMaybeDecoder(number),
    publicDetailedDescriptionHtml: explicitMaybeDecoder(alreadyTranslatedTextDecoder),
    whoShouldAttendHtml: explicitMaybeDecoder(alreadyTranslatedTextDecoder),
    keyTakeawaysHtml: explicitMaybeDecoder(alreadyTranslatedTextDecoder),
    howItWorksHtml: explicitMaybeDecoder(alreadyTranslatedTextDecoder),
    programStructureAndFeaturesHtml: explicitMaybeDecoder(alreadyTranslatedTextDecoder),
    schoolPartnerName: alreadyTranslatedTextDecoder,
    supportedLanguageCodes: array(supportedLanguageCodeDecoder),
    primaryCompetencies: array(alreadyTranslatedTextDecoder),
  },
  snakeCase,
);
export type ExperienceDictionaryEntry = InferType<typeof experienceDictionaryEntryPayloadDecoder>;

const experienceDictionaryEntryLinksDecoder = createDecoderFromStructure(
  {
    self: linkDecoder,
    schoolPartner: linkDecoder,
    productDetails: explicitMaybeDecoder(linkDecoder),
    digitalCert: explicitMaybeDecoder(linkDecoder), // Optional link to digital certificate
    supportedLanguages: linkDecoder, // Link to supported languages
    experiencePrices: linkDecoder,
    overviewPdf: explicitMaybeDecoder(linkDecoder), // Optional link to an overview PDF
  },
  snakeCase,
);

const experienceDictionaryEntryDecoder = createDecoderFromStructure({
  payload: experienceDictionaryEntryPayloadDecoder,
  links: experienceDictionaryEntryLinksDecoder,
});

export const experiencesDictionaryDecoder: Decoder<Resource<ExperienceDictionaryEntryResponse[]>> =
  resourceDecoder(field('experiences', array(experienceDictionaryEntryDecoder)));

export function isLeadershipCoachingExperience(
  experience: ExperienceDictionaryEntry,
): experience is ExperienceDictionaryEntry & { offeringType: 'coaching' } {
  return experience.offeringType === 'coaching';
}

export function isGroupCoachingExperience(
  experience: ExperienceDictionaryEntry,
): experience is ExperienceDictionaryEntry & { offeringType: 'group-coaching' } {
  return experience.offeringType === 'group-coaching';
}
