import { CardBody, CardFooter, CardHeader } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { ExperienceDictionaryEntryResponse } from '../../../../ExperiencesDictionary/Types';
import { PortalExperienceProvider } from '../../../PortalExperience/PortalExperienceContextProvider';
import ExperienceBody from '../../ExperienceBody';
import ExperienceFooter from '../../ExperienceFooter';
import ExperienceHeaderEndIcon from '../../ExperienceHeaderEndIcon';
import ExperienceHeaderLogo from '../../ExperienceHeaderLogo';
import ProgramExperienceCard from '../../ProgramExperienceCard';

interface Props {
  experience: ExperienceDictionaryEntryResponse;
}

function ExperienceCard({ experience }: Props) {
  return (
    <PortalExperienceProvider experience={experience}>
      <ProgramExperienceCard
        className={
          'pointer-events-none h-full min-h-[14rem] w-[20rem] bg-white text-gray-800 shadow-tile-preview'
        }
      >
        <CardHeader
          className={'h-[2.375rem] rounded-t py-1.5'}
          endContent={<ExperienceHeaderEndIcon />}
        >
          <ExperienceHeaderLogo />
        </CardHeader>
        <CardBody>
          <ExperienceBody />
        </CardBody>
        <CardFooter>
          <ExperienceFooter />
        </CardFooter>
      </ProgramExperienceCard>
    </PortalExperienceProvider>
  );
}

export default observer(ExperienceCard);
