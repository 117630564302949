import { useEffect, useState } from 'react';
import availabilitiesStore, { Availability } from '.';
import { findLink } from '../../../../LinkyLinky';
import experiencesStore from '../../ExperiencesStore';
import { usePortalExperienceContext } from '../../PortalExperience/UsePortalExperienceContext';

function useAvailabilitiesStore() {
  const experience = usePortalExperienceContext();

  const [availabilities, setAvailabilities] = useState<Availability[]>([]);

  useEffect(() => {
    findLink('availabilities', experiencesStore.links).do((link) => {
      availabilitiesStore.loadAllAvailabilities(link);
    });
  }, [experiencesStore.links]);

  useEffect(() => {
    const subscription = experience.map((experience) => {
      const availabilityBehavior = availabilitiesStore.getAvailabilities(experience.payload.id);
      return availabilityBehavior.subscribe(setAvailabilities);
    });

    return () => {
      subscription.do((sub) => sub.unsubscribe());
    };
  }, [experience]);

  return {
    availabilities,
  };
}

export default useAvailabilitiesStore;
