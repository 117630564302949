import { fromNullable } from 'maybeasy';
import { experiencesDictionary } from '../../ExperiencesDictionary';
import { ExperienceDictionaryEntryResponse } from '../../ExperiencesDictionary/Types';
import { PortalExperienceContext } from './PortalExperienceContext';

interface PortalExperiencesProviderProps {
  experience: ExperienceDictionaryEntryResponse;
  children: React.ReactNode;
}

export function PortalExperienceProvider({ experience, children }: PortalExperiencesProviderProps) {
  const experienceEntry = experiencesDictionary[experience.payload.id];
  return (
    <PortalExperienceContext.Provider value={fromNullable(experienceEntry)}>
      {children}
    </PortalExperienceContext.Provider>
  );
}
